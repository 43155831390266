<template>
  <div
    class="d3-op px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-4">
        <div style="position: relative;width: 100%;padding-bottom: 56.25%;">
          <iframe
            src="https://player.vimeo.com/video/489987488"
            style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-4">
        <TitleComponent
          style="padding-bottom:1rem;"
          title="D3 Operational Support"
          size="large"
        />
        <Paragraph
          ><p>
            Decommissioning, decontamination, and demolition (D3) of facilities
            used for the processing of energetic materials requires a unique
            explosives-safety skill set. Energetic materials and compositions
            can accumulate over time within process equipment, ventilation
            systems, process and vacuum piping, electrical equipment, drains and
            sumps, and other hidden spaces, therefore posing considerable
            potential risk to personnel performing D3 operations.
          </p>
          <p>
            SMS has developed safe and reliable risk management systems to
            assess, categorize, decontaminate, disassemble equipment, and
            demolish such facilities. SMS has evaluated the suitability of
            various technologies for remote pipe cutting, borescope inspection,
            thermal and/or chemical treatment, and destruction in place. Whether
            items are meant for reuse, recycle, or disposal, SMS can lead or
            assist your team to perform D3 activities in a safe and efficient
            manner.
          </p>
          <p>
            We have successfully applied our risk-based approach to a wide
            variety of D3 operations. SMS provided key safety and design support
            for the D3 operations of the Pratt &amp; Whitney Rocketdyne (PWR)
            Chemical Systems Division manufacturing facility located in San
            Jose, California (2004 – 2009). The PWR facility consisted of over
            230 structures on 5,200 acres. Utilizing systematic risk management,
            all high-hazard buildings with associated processing equipment have
            been safely removed, requiring over 900,000 man hours worked under
            difficult and challenging circumstances with no Lost Time injuries.
            Click
            <router-link
              style="color:#aa222c;"
              title="D3 Operation Experience"
              to="/risk-management/d3-operations/d3-experience"
              >here</router-link
            >
            to read more on the details of the D3 work completed at the PWR
            facility.
          </p>
          <p>
            D3 activities may provide sufficient energy for electrostatic
            discharge (ESD), friction, impact, or thermal stimulus to cause
            ignition (flame) or initiation (shock) of energetic materials. D3
            operations render equipment, facilities, etc. safe from explosive
            hazards, and facilitates reuse, recycle, or disposal.
          </p>
        </Paragraph>
      </div>
      <div class="w-full mb-6">
        <TitleComponent title="Scope of Each D3 Phase" />
        <TabsComponent :tabs="tabs" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import TabsComponent from "@/components/TabsComponent";

export default {
  metaInfo: {
    title: "Decommissioning, decontamination, and demolition",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Decommissioning, decontamination, and demolition (D3) render safe facilities and equipment used in the processing of energetic materials. SMS has developed risk management systems to effectively assess D3 equipment and oversee D3 operations."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    TabsComponent
  },
  data: () => {
    return {
      tabs: [
        {
          name: "Decommissioning",
          content: `Current operations are brought to an ordered or orderly shutdown. A cursory cleanup may (or may not) be performed. The activities at this level are best described as: “Mop the floor, lock the door.” All further processing activities are suspended.`
        },
        {
          name: "Decontamination",
          content: `Equipment and facilities are evaluated and designated a cleanliness level (e.g. 1X, 3X, 5X, or 0) that indicates the amount of energetic material potentially present. Equipment and contaminated areas are then decontaminated to an acceptable level.`
        },
        {
          name: "Demolition",
          content: `The decontaminated structure is taken down and hauled away. Mechanical equipment (such as shielded excavators with hydraulic claws and bulldozers) are typically used.`
        }
      ]
    };
  }
};
</script>
